import React from "react";
import { SmartCaptcha } from '@yandex/smart-captcha';
import { useState } from "react";
import axios from "axios";

function ComponentWithCaptcha(props) {
  const [smartToken, setSmartToken] = useState('');
  const [resetCaptcha, setResetCaptcha] = useState(0);
   const handleReset = () => setResetCaptcha((prev) => prev + 1);
   React.useEffect(()=>{
    if (smartToken != "") {
      axios({
    method: "POST",
    url: "./captcha.php",
    headers: {
      "Access-Control-Allow-Headers": "content-type",
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    data: {
      token: smartToken,
    },
  })
  .then((res) => {
    console.log(res.data)
    console.log(res.data === "Passed")
    props.setCaptcha(res.data);
    handleReset();
    if (res.data === "Passed") {
      props.setOpenPopupCaptcha(false);
    }
  })
  .catch((err) => console.log(err));
    }
   },[smartToken])
   
    return (
    <SmartCaptcha key={resetCaptcha} sitekey="ysc1_nLf8OeNLSS2pALZLSYPxKjgIh2KEeojkhZ39aNaNe19b4b3a" onSuccess={setSmartToken} />
  );
  
}

export default ComponentWithCaptcha;
